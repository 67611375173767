import { useEffect, useState } from "react";
import "./App.css";
import dayjs from "dayjs";

export default function StoryPost(props) {
  // 시간 표시를 위한 함수
  function getElapsedTime(date) {
    const start = new Date(date);
    const end = new Date();

    const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
    if (seconds < 60) return "방금 전";

    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)}분 전`;

    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)}시간 전`;

    const days = hours / 24;
    if (days < 7) return `${Math.floor(days)}일 전`;

    return `${start.toLocaleDateString()}`;
  }
  const [displayedTime, setDisplayedTime] = useState(
    getElapsedTime(+new Date(props.requestedAt))
  );
  function displaySpecificTime() {
    setDisplayedTime(dayjs(new Date(props.requestedAt)).format("YYYY.MM.DD."));
  }

  // 좋아요 버튼 눌렀을 때
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  function pressLikeButton() {
    setLikeButtonPressed(true);
    setTimeout(() => {
      setLikeButtonPressed(false);
    }, 3000);
  }

  // 사연 제목이 길 때 크기 축소
  const [isTitleLong, setIsTitleLong] = useState(false);
  useEffect(() => {
    if (props.title !== undefined) {
      if (props.title.length > 10) {
        setIsTitleLong(true);
      }
    }
  }, [props.title]);

  return (
    <div
      className="storypost px-4 py-2 border border-start-0 border-end-0 position-relative border-bottom-0"
      id={props.id}
    >
      <div className="">
        <span
          className={
            "fs-2 d-inline-block fw-bolder my-2" +
            (isTitleLong ? " fs-3 my-1" : "")
          }
          style={{
            width: "80%",
            lineHeight: "1.1",
          }}
        >
          {props.title}
        </span>
        <span
          onClick={displaySpecificTime}
          className="position-absolute end-0 me-4 mt-2 "
        >
          {displayedTime}
        </span>
      </div>
      <span className="fs-5">
        {props.content}
        {props.isAdmin ? (
          <>
            {" "}
            -{" "}
            <span className="fw-light fs-6">
              {props.requestedByName}#{props.requestedById}
            </span>
          </>
        ) : (
          <></>
        )}
      </span>
      <br />
      <button
        type="button"
        className={
          "likebutton btn border border-danger mt-3 mb-2 px-2 py-1 " +
          (likeButtonPressed ? "disabled btn-danger" : "")
        }
        onClick={() => {
          props.likeStory(props.id);
          pressLikeButton();
        }}
      >
        <span
          id="heart"
          style={{
            color: "#B01E28",
          }}
        >
          ♥
        </span>{" "}
        {props.liked}
      </button>
      {props.isAdmin ? (
        <div>
          {props.broadcasted ? (
            <button className="playbutton btn disabled border border-primary me-1 mt-2">
              ★ 이미 방송됨
            </button>
          ) : (
            <button
              className="playbutton btn border border-primary me-1 mt-2"
              onClick={() => props.broadcastStory(props.id)}
            >
              ★ 방송됨으로 표시
            </button>
          )}
          <button
            className="playbutton btn border border-danger me-1 mt-2"
            onClick={() => props.deleteStory(props.id)}
          >
            ✕ 삭제
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
