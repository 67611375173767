import { useEffect, useState } from "react";
import "./App.css";
import dayjs from "dayjs";

function SongPost(props) {
  // 시간 표시를 위한 함수
  function getElapsedTime(date) {
    const start = new Date(date);
    const end = new Date();

    const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
    if (seconds < 60) return "방금 전";

    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)}분 전`;

    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)}시간 전`;

    const days = hours / 24;
    if (days < 7) return `${Math.floor(days)}일 전`;

    return `${start.toLocaleDateString()}`;
  }
  const [displayedTime, setDisplayedTime] = useState(
    getElapsedTime(+new Date(props.requestedAt))
  );
  function displaySpecificTime() {
    setDisplayedTime(dayjs(new Date(props.requestedAt)).format("YYYY.MM.DD."));
  }

  // 좋아요 버튼 눌렀을 때
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  function pressLikeButton() {
    setLikeButtonPressed(true);
    setTimeout(() => {
      setLikeButtonPressed(false);
    }, 3000);
  }

  // 노래 제목이 길 때 크기 축소
  const [isSongNameLong, setIsSongNameLong] = useState(false);
  useEffect(() => {
    if (props.songName.length > 16) {
      setIsSongNameLong(true);
    }
  }, [props.songName]);

  return (
    <div
      className="songpost px-4 py-2 border border-start-0 border-end-0 border-bottom-0 position-relative"
      id={props.id}
    >
      <div className="ms-4 mt-2">
        <span className="fw-bold fs-5">{props.requestedByName}</span>
        <span className="fw-light fs-6">#{props.requestedById}</span>
        <span
          onClick={displaySpecificTime}
          className="position-absolute end-0 me-5 mt-1 "
        >
          {displayedTime}
        </span>
      </div>
      <div className="d-flex flex-column m-3 mx-4 mt-1">
        <img
          src={props.coverURL}
          alt="앨범 이미지"
          className="rounded-4 shadow-lg"
          id="albumImage"
          style={{
            width: "auto",
            height: "auto",
          }}
          loading="lazy"
        />
        <div className="position-relative d-flex flex-column">
          <span
            id="songName"
            className={
              "fw-bold d-inline-block mt-3 fs-1 mb-1 text-capitalize" +
              (isSongNameLong ? " fs-2 mt-21  " : "")
            }
            style={{
              width: "82.5%",
              lineHeight: "1.1",
            }}
          >
            {props.songName}
          </span>
          <span className="fw-light fs-4 text-capitalize">{props.artist}</span>
          <button
            type="button"
            className={
              "likebutton btn position-absolute end-0 top-50 translate-middle-y border border-danger me-1 px-2 py-1 " +
              (likeButtonPressed ? "disabled btn-danger" : "")
            }
            onClick={() => {
              props.likeSong(props.id);
              pressLikeButton();
            }}
          >
            <span
              id="heart"
              style={{
                color: "#B01E28",
              }}
            >
              ♥
            </span>{" "}
            {props.liked}
          </button>
          {props.isAdmin ? (
            <div>
              <button
                className="playbutton btn border border-primary me-1 mt-2"
                onClick={() => props.playSong(props.id)}
              >
                ♪ 재생됨으로 표시
              </button>
              <button
                className="playbutton btn border border-danger me-1 mt-2"
                onClick={() => props.deleteSong(props.id)}
              >
                ✕ 삭제
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default SongPost;
