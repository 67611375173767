import "./App.css";
import SongPost from "./SongPost";
import StoryPost from "./StoryPost";
import { useEffect, useState } from "react";
import logo from "./logo.webp";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { ClientJS } from "clientjs";
import { Toaster, toast } from "react-hot-toast";

function App() {
  const [serverUrl, setServerUrl] = useState(process.env.REACT_APP_SERVER_URL);
  const [mode, setMode] = useState("song");
  const [admin, setAdmin] = useState(false);
  const [fingerprint, setFingerprint] = useState("");

  useEffect(() => {
    // 프로덕션 상황일 때와 개발 상황일 때 서버 주소 다르게 설정
    if (process.env.NODE_ENV === "production") {
      setServerUrl(window.location.protocol + "//" + window.location.host);
    } else {
      setServerUrl("http://localhost:3000");
    }

    // 모바일일 때 모드에 따라 데이터 불러오기
    if (isMobile && mode === "song") {
      fetchSongs();
    } else if (isMobile && mode === "story") {
      fetchStories();
    } else {
      fetchSongs();
      fetchStories();
    }
  }, [serverUrl, admin, mode, fingerprint]);

  useEffect(() => {
    // 클라이언트 fingerprint 생성
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    console.log(fingerprint);
    setFingerprint(fingerprint);

    // 관리자 권한 확인
    if (!admin) {
      console.log("관리자 권한 확인 중... at " + serverUrl);
      fetch(`${serverUrl}/admin/check?fingerprint=${fingerprint}`).then(
        (res) => {
          if (res.status === 200) {
            setAdmin(true);
            toast.success("관리자 권한이 활성화되었어요.");
          } else if (res.status === 401) {
            setAdmin(false);
            console.log("관리자 권한이 없어요.");
          }
        }
      );
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get("adminvalidated") === "true") {
      toast.success("관리자 인증을 성공했어요.");
    }
  }, [serverUrl]);

  // 신청곡 파트
  const [songs, setSongs] = useState([]);

  // 신청곡 불러오기
  function fetchSongs() {
    fetch(`${serverUrl}/song/view`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          data.sort(function (a, b) {
            if (a.requestedAt < b.requestedAt) {
              return 1;
            }
            if (a.requestedAt > b.requestedAt) {
              return -1;
            }
            return 0;
          });
          setSongs(data);
        });
      } else {
        toast.error(
          "서버에서 데이터를 불러오는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }

  // 곡 좋아요
  function likeSong(id) {
    fetch(`${serverUrl}/song/like?id=${id}`).then((res) => {
      if (res.status === 200) {
        res.text().then((data) => {
          fetchSongs();
        });
      } else {
        toast.error(
          "서버에서 데이터를 불러오는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }
  // 곡 재생됨 표시
  function playSong(id) {
    fetch(`${serverUrl}/song/play?id=${id}`).then((res) => {
      if (res.status === 200) {
        res.text().then((data) => {
          console.log(data);
          fetchSongs();
        });
      } else {
        toast.error(
          "서버에서 데이터를 불러오는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }
  // 곡 삭제
  function deleteSong(id) {
    fetch(`${serverUrl}/song/delete?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        admin: admin,
      },
    }).then((res) => {
      if (res.status === 200) {
        toast.success("곡이 삭제되었어요.");
        res.text().then((data) => {
          fetchSongs();
        });
      } else {
        toast.error(
          "서버에서 데이터를 불러오는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }
  // 곡 신청
  function requestSong() {
    const songName = document.getElementById("songName").value;
    const artist = document.getElementById("artist").value;
    const applicantname = document.getElementById("applicantname").value;
    const applicantid = document.getElementById("applicantid").value;

    fetch(`${serverUrl}/song/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        admin: admin,
      },
      body: JSON.stringify({
        songname: songName,
        artist: artist,
        requestedbyname: applicantname,
        requestedbyid: applicantid,
      }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success(`신청되었어요.`);
      } else if (res.status === 400) {
        toast.error("신청하신 곡은 19금이에요. 건전한 곡을 신청해 주세요.");
      } else if (res.status === 418) {
        toast.error("한 번만 눌러 주세요.");
      } else {
        toast.error(
          "곡을 신청하지 못했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
      res.text().then((data) => {
        console.log(data);
        fetchSongs();
      });
    });
  }

  // 곡 플레이리스트 가져오기
  function fetchPlaylist() {
    fetch(`${serverUrl}/song/getyoutubeplaylisturl`).then((res) => {
      if (res.status === 200) {
        res.text().then((data) => {
          console.log(data);
          navigator.clipboard
            .writeText(data)
            .then(() => {
              toast.success("플레이리스트 URL이 복사됐어요.");
            })
            .catch(() => {
              toast.error(
                "플레이리스트 URL을 복사하지 못했어요. 다시 시도해 주세요."
              );
            });
        });
      } else {
        toast.error(
          "서버에서 데이터를 불러오는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }

  // 사연 파트
  const [stories, setStories] = useState([]);

  // 사연 불러오기
  function fetchStories() {
    fetch(`${serverUrl}/story/view?admin=${admin}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        admin: admin,
      },
    }).then((res) => {
      res.json().then((data) => {
        data.sort(function (a, b) {
          if (a.requestedAt < b.requestedAt) {
            return 1;
          }
          if (a.requestedAt > b.requestedAt) {
            return -1;
          }
          return 0;
        });
        setStories(data);
      });
    });
  }

  // 사연 좋아요
  function likeStory(id) {
    fetch(`${serverUrl}/story/like?id=${id}`).then((res) => {
      res.text().then((data) => {
        console.log(data);
        fetchStories();
      });
    });
  }

  // 사연 방송됨 표시
  function broadcastStory(id) {
    fetch(`${serverUrl}/story/broadcast?id=${id}`).then((res) => {
      res.text().then((data) => {
        console.log(data);
        fetchStories();
      });
    });
  }

  // 사연 삭제
  function deleteStory(id) {
    fetch(`${serverUrl}/story/delete?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        admin: admin,
      },
    }).then((res) => {
      if (res.status === 200) {
        toast.success("사연이 삭제되었어요.");
        res.text().then((data) => {
          console.log(data);
          fetchStories();
        });
      } else {
        toast.error("사연을 삭제하지 못했어요.");
      }
    });
  }

  // 사연 업로드
  function requestStory() {
    const title = document.getElementById("storytitle").value;
    const content = document.getElementById("storycontent").value;
    const requestedByName = document.getElementById("applicantname").value;
    const requestedById = document.getElementById("applicantid").value;

    fetch(`${serverUrl}/story/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        content: content,
        requestedbyname: requestedByName,
        requestedbyid: requestedById,
      }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success(
          "사연이 제출되었어요. 사연이 방송된 후에 이 사이트에서 사연을 확인할 수 있어요."
        );
      } else if (res.status === 418) {
        toast.error("한 번만 눌러 주세요.");
      } else {
        toast.error(
          "사연 제출에 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
      res.text().then((data) => {
        console.log(data);
        fetchStories();
      });
    });
  }

  // 관리자 기능 활성화
  function enableAdminFeature() {
    const email = prompt("관리자 이메일을 입력하세요.");

    fetch(`${serverUrl}/admin/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ fingerprint: fingerprint, email: email }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success("인증 이메일이 발송됐어요. 이메일을 확인해 주세요.");
        console.log(res.text());
      } else {
        toast.error(
          "관리자 권한을 얻는 데 실패했어요. 다시 시도하거나 방송부로 문의해 주세요."
        );
      }
    });
  }

  const requestSongComponent = (
    <div id="request" className="m-4 mx-4 position-relative mb-5">
      {admin ? (
        <>
          <button
            className="btn mb-2 me-1"
            onClick={() => {
              fetchPlaylist();
            }}
          >
            ▶️
          </button>
          <button
            className="btn mb-2 me-1"
            onClick={() => {
              fetchSongs();
            }}
          >
            ↺
          </button>
          <button
            className="btn mb-2 me-1"
            onClick={() => {
              songs.reverse();
              setSongs([...songs]);
            }}
          >
            ↕️
          </button>
        </>
      ) : (
        <></>
      )}
      <label htmlFor="songName" className="fs-4 fw-bolder mb-2">
        곡 신청하기
      </label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="곡명"
          aria-label="Song Name"
          id="songName"
        />
        <span className="input-group-text">-</span>
        <input
          type="text"
          className="form-control"
          placeholder="아티스트"
          aria-label="Artist"
          id="artist"
        />
      </div>
      <div className="input-group">
        <input
          type="text"
          aria-label="id"
          className="form-control"
          placeholder="신청자 학번"
          id="applicantid"
        />
        <input
          type="text"
          aria-label="Last name"
          className="form-control"
          id="applicantname"
          placeholder="신청자 이름"
        />
      </div>
      <button
        type="button"
        className="btn btn-primary mt-2 position-absolute end-0"
        onClick={requestSong}
      >
        신청하기
      </button>
    </div>
  );

  const requestStoryComponent = (
    <div id="request" className="m-4 mx-4 position-relative mb-5">
      {admin ? (
        <>
          <button
            className="btn mb-2 me-1"
            onClick={() => {
              fetchStories();
            }}
          >
            ↺
          </button>
          <button
            className="btn mb-2 me-1"
            onClick={() => {
              stories.reverse();
              setStories([...stories]);
            }}
          >
            ↕️
          </button>
        </>
      ) : (
        <></>
      )}
      <label htmlFor="storytitle" className="fs-4 fw-bolder mb-2">
        사연 신청하기
      </label>
      <input
        type="text"
        className="form-control"
        placeholder="제목"
        aria-label="Story Title"
        id="storytitle"
      />
      <textarea
        type="text"
        className="form-control my-2"
        placeholder="내용"
        aria-label="Story Content"
        id="storycontent"
        style={{ height: "100px" }}
      />

      <div className="input-group">
        <input
          type="text"
          aria-label="id"
          className="form-control"
          placeholder="신청자 학번"
          id="applicantid"
        />
        <input
          type="text"
          aria-label="Last name"
          className="form-control"
          id="applicantname"
          placeholder="신청자 이름 (익명 보장)"
        />
      </div>
      <button
        type="button"
        className="btn btn-primary mt-2 position-absolute end-0"
        onClick={requestStory}
      >
        신청하기
      </button>
    </div>
  );

  return (
    <div className="App">
      <Toaster />
      <nav
        className="navbar bg-body-tertiary"
        style={
          isBrowser
            ? {
                height: "56px",
              }
            : null
        }
      >
        <div className="container-fluid position-relative">
          <a className="navbar-brand fw-bolder" href="#">
            <img
              src={logo}
              alt="로고"
              width="24"
              height="24"
              className="me-1 rounded-circle d-inline-block align-text-top"
            />
            연신중 방송부 {admin ? "(관리자)" : ""}
          </a>
          <div>
            {isMobile ? (
              <button
                className="btn"
                onClick={
                  mode === "story"
                    ? () => setMode("song")
                    : () => setMode("story")
                }
              >
                {mode === "story" ? "신청곡" : "사연"}
              </button>
            ) : (
              <></>
            )}
            {!admin ? (
              <button className="btn" onClick={enableAdminFeature}>
                Admin
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </nav>

      <BrowserView>
        <div
          className="row justify-content-between"
          style={{
            width: "100vw",
            margin: "0",
          }}
        >
          <div
            className="col browser-postview"
            style={{
              overflow: "scroll",
              height: "calc(100vh - 56px)",
            }}
          >
            {requestSongComponent}
            <br />
            {songs.map((song) => (
              <SongPost
                songName={song.songName}
                artist={song.artist}
                requestedByName={song.requestedByName}
                requestedById={song.requestedById}
                requestedAt={song.requestedAt}
                liked={song.liked}
                played={song.played}
                id={song._id}
                likeSong={likeSong}
                playSong={playSong}
                deleteSong={deleteSong}
                key={song._id}
                isAdmin={admin}
                coverURL={song.coverURL}
              />
            ))}
          </div>
          <div
            className="vr my-4"
            style={{
              padding: "0",
            }}
          ></div>
          <div
            className="col-7 browser-postview"
            style={{
              overflow: "scroll",
              height: "calc(100vh - 56px)",
            }}
          >
            {requestStoryComponent}
            <br />
            {stories.map((story) => (
              <StoryPost
                title={story.title}
                content={story.content}
                requestedByName={story.requestedByName}
                requestedById={story.requestedById}
                requestedAt={story.requestedAt}
                liked={story.liked}
                id={story._id}
                broadcasted={story.broadcasted}
                likeStory={likeStory}
                broadcastStory={broadcastStory}
                deleteStory={deleteStory}
                isAdmin={admin}
                key={story._id}
              />
            ))}
          </div>
        </div>
      </BrowserView>

      <MobileView>
        {mode === "song" ? (
          <>
            {requestSongComponent}
            <br />
            {songs.map((song) => (
              <SongPost
                songName={song.songName}
                artist={song.artist}
                requestedByName={song.requestedByName}
                requestedById={song.requestedById}
                requestedAt={song.requestedAt}
                liked={song.liked}
                played={song.played}
                id={song._id}
                likeSong={likeSong}
                playSong={playSong}
                deleteSong={deleteSong}
                key={song._id}
                isAdmin={admin}
                coverURL={song.coverURL}
              />
            ))}
          </>
        ) : (
          <>
            {requestStoryComponent}
            <br />
            {stories.map((story) => (
              <StoryPost
                title={story.title}
                content={story.content}
                requestedByName={story.requestedByName}
                requestedById={story.requestedById}
                requestedAt={story.requestedAt}
                liked={story.liked}
                id={story._id}
                broadcasted={story.broadcasted}
                likeStory={likeStory}
                broadcastStory={broadcastStory}
                deleteStory={deleteStory}
                isAdmin={admin}
                key={story._id}
              />
            ))}
          </>
        )}
      </MobileView>
    </div>
  );
}

export default App;
